import * as React from "react";
import AccountApp from "account";
import SEO from "../components/seo";

const AccountPage = (): JSX.Element => {
  return (
    <React.Fragment>
      <SEO title="Account" />
      <AccountApp basepath="account" />
    </React.Fragment>
  );
};

export default AccountPage;
